exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-index-tsx": () => import("./../../../src/pages/download/index.tsx" /* webpackChunkName: "component---src-pages-download-index-tsx" */),
  "component---src-pages-download-motion-tray-app-tsx": () => import("./../../../src/pages/download/motion-tray-app.tsx" /* webpackChunkName: "component---src-pages-download-motion-tray-app-tsx" */),
  "component---src-pages-download-motion-tray-simhub-tsx": () => import("./../../../src/pages/download/motion-tray-simhub.tsx" /* webpackChunkName: "component---src-pages-download-motion-tray-simhub-tsx" */),
  "component---src-pages-download-motion-tray-streamdeck-iconpack-tsx": () => import("./../../../src/pages/download/motion-tray-streamdeck-iconpack.tsx" /* webpackChunkName: "component---src-pages-download-motion-tray-streamdeck-iconpack-tsx" */),
  "component---src-pages-features-buttonbox-tsx": () => import("./../../../src/pages/features/buttonbox.tsx" /* webpackChunkName: "component---src-pages-features-buttonbox-tsx" */),
  "component---src-pages-features-desktop-tsx": () => import("./../../../src/pages/features/desktop.tsx" /* webpackChunkName: "component---src-pages-features-desktop-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-keyboard-tsx": () => import("./../../../src/pages/features/keyboard.tsx" /* webpackChunkName: "component---src-pages-features-keyboard-tsx" */),
  "component---src-pages-features-mobile-tsx": () => import("./../../../src/pages/features/mobile.tsx" /* webpackChunkName: "component---src-pages-features-mobile-tsx" */),
  "component---src-pages-features-notifications-tsx": () => import("./../../../src/pages/features/notifications.tsx" /* webpackChunkName: "component---src-pages-features-notifications-tsx" */),
  "component---src-pages-features-profiles-tsx": () => import("./../../../src/pages/features/profiles.tsx" /* webpackChunkName: "component---src-pages-features-profiles-tsx" */),
  "component---src-pages-features-share-tsx": () => import("./../../../src/pages/features/share.tsx" /* webpackChunkName: "component---src-pages-features-share-tsx" */),
  "component---src-pages-features-simhub-tsx": () => import("./../../../src/pages/features/simhub.tsx" /* webpackChunkName: "component---src-pages-features-simhub-tsx" */),
  "component---src-pages-features-wheel-tsx": () => import("./../../../src/pages/features/wheel.tsx" /* webpackChunkName: "component---src-pages-features-wheel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-unsubscribed-tsx": () => import("./../../../src/pages/unsubscribed.tsx" /* webpackChunkName: "component---src-pages-unsubscribed-tsx" */)
}

